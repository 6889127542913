// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
    /* Define all your color variables here */
        --color-offwhite: #F7F7F7;
        --color-light-gray: #E4E4E4;
        --color-medium-gray: #b7b8b7;
        --color-dark-gray: #1e1e1e;
        --color-white: #ffffff;
        --color-black: #1A1818;
    
    /* Just in case they forgot... */
    --color-white: #fff;
    --color-black: #000;
  }
  
  
  .text-offwhite {
    color: #F7F7F7;
  }
  .bg-offwhite {
    background-color: #F7F7F7;
  }
  
  
  .text-light-gray {
    color: #E4E4E4;
  }
  .bg-light-gray {
    background-color: #E4E4E4;
  }
  
  
  .text-medium-gray {
    color: #b7b8b7;
  }
  .bg-medium-gray {
    background-color: #b7b8b7;
  }
  
  
  .text-dark-gray {
    color: #707070;
  }
  .bg-dark-gray {
    background-color: #707070;
  }
  
  
  .text-white {
    color: #ffffff;
  }
  .bg-white {
    background-color: #ffffff;
  }
  
  
  .text-black {
    color: #1A1818;
  }
  .bg-black {
    background-color: #1A1818;
  }

.bg-fullbox{
  background-color: #D9D9D9;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/dynamic/colors.css"],"names":[],"mappings":";AACA;IACI,yCAAyC;QACrC,yBAAyB;QACzB,2BAA2B;QAC3B,4BAA4B;QAC5B,0BAA0B;QAC1B,sBAAsB;QACtB,sBAAsB;;IAE1B,gCAAgC;IAChC,mBAAmB;IACnB,mBAAmB;EACrB;;;EAGA;IACE,cAAc;EAChB;EACA;IACE,yBAAyB;EAC3B;;;EAGA;IACE,cAAc;EAChB;EACA;IACE,yBAAyB;EAC3B;;;EAGA;IACE,cAAc;EAChB;EACA;IACE,yBAAyB;EAC3B;;;EAGA;IACE,cAAc;EAChB;EACA;IACE,yBAAyB;EAC3B;;;EAGA;IACE,cAAc;EAChB;EACA;IACE,yBAAyB;EAC3B;;;EAGA;IACE,cAAc;EAChB;EACA;IACE,yBAAyB;EAC3B;;AAEF;EACE,yBAAyB;AAC3B","sourcesContent":["\n:root {\n    /* Define all your color variables here */\n        --color-offwhite: #F7F7F7;\n        --color-light-gray: #E4E4E4;\n        --color-medium-gray: #b7b8b7;\n        --color-dark-gray: #1e1e1e;\n        --color-white: #ffffff;\n        --color-black: #1A1818;\n    \n    /* Just in case they forgot... */\n    --color-white: #fff;\n    --color-black: #000;\n  }\n  \n  \n  .text-offwhite {\n    color: #F7F7F7;\n  }\n  .bg-offwhite {\n    background-color: #F7F7F7;\n  }\n  \n  \n  .text-light-gray {\n    color: #E4E4E4;\n  }\n  .bg-light-gray {\n    background-color: #E4E4E4;\n  }\n  \n  \n  .text-medium-gray {\n    color: #b7b8b7;\n  }\n  .bg-medium-gray {\n    background-color: #b7b8b7;\n  }\n  \n  \n  .text-dark-gray {\n    color: #707070;\n  }\n  .bg-dark-gray {\n    background-color: #707070;\n  }\n  \n  \n  .text-white {\n    color: #ffffff;\n  }\n  .bg-white {\n    background-color: #ffffff;\n  }\n  \n  \n  .text-black {\n    color: #1A1818;\n  }\n  .bg-black {\n    background-color: #1A1818;\n  }\n\n.bg-fullbox{\n  background-color: #D9D9D9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
