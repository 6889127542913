// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
    Theme css
*/

button, .button {
    display:flex;
    width: -moz-fit-content;
    width: fit-content;
    color:black;
    font-weight:800;
    font-size:14px;
    position:relative;
    cursor:pointer;
  }
  
  .block ul li p {
    margin-bottom:0;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/theme.css"],"names":[],"mappings":"AAAA;;CAEC;;AAED;IACI,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB","sourcesContent":["/*\n    Theme css\n*/\n\nbutton, .button {\n    display:flex;\n    width: fit-content;\n    color:black;\n    font-weight:800;\n    font-size:14px;\n    position:relative;\n    cursor:pointer;\n  }\n  \n  .block ul li p {\n    margin-bottom:0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
