import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./assets/styles/reset.css"
import "./assets/styles/dynamic/colors.css"
import "./assets/styles/dynamic/typekit.css"
import "./assets/styles/dynamic/ui.css"
import "./assets/styles/dynamic/layouts.css"
import "./assets/styles/theme.css"
import "./assets/styles/shell.css"
import "./assets/styles/util.css"
import "./assets/styles/templates/home.css"
// import "./assets/styles/templates/warranty.css"
import "./assets/styles/blocks/embed_video.css"
import "./assets/styles/blocks/slider.css"
// import "./assets/styles/blocks/lightbox_grid.css"
import "./assets/styles/blocks/hr.css"
import "./assets/styles/blocks/padding.css"
import "./assets/styles/blocks/fancy_split.css"
import "./assets/styles/blocks/simple_split.css"
import "./assets/styles/blocks/image.css"
import "./assets/styles/blocks/portfolio-preview.css"
import "./assets/styles/custom.css"
import "glightbox/dist/css/glightbox.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


