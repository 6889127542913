// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-type-embed_video .vid-inner {
  position: relative;
}

.block-type-embed_video .vid-inner .poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-white);
}

.block-type-embed_video video {
  width: 100%;
}
.poster.visible {
    display: none !important;
}

@media screen and (max-width:980px) {
  .block-type-embed_video .vid-inner .poster img {
    width: 30px;
    height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/blocks/embed_video.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;AACA;IACI,wBAAwB;AAC5B;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".block-type-embed_video .vid-inner {\n  position: relative;\n}\n\n.block-type-embed_video .vid-inner .poster {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 10;\n  background-size: cover;\n  background-position: 50% 50%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  color: var(--color-white);\n}\n\n.block-type-embed_video video {\n  width: 100%;\n}\n.poster.visible {\n    display: none !important;\n}\n\n@media screen and (max-width:980px) {\n  .block-type-embed_video .vid-inner .poster img {\n    width: 30px;\n    height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
