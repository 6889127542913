import AOS from "aos";
import React, { Suspense, useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/styles/aos.css";
const Header = React.lazy(() => import("./components/global/header"));
const Footer = React.lazy(() => import("./components/global/footer"));
const Home = React.lazy(() => import("./pages/home"));
const Showroom = React.lazy(() => import("./pages/showroom"));
const Theteam = React.lazy(() => import("./pages/theteam"));
const Whatwedo = React.lazy(() => import("./pages/whatwedo"));
const OurProcess = React.lazy(() => import("./pages/our-process"));
const ExplorePlans = React.lazy(() => import("./pages/explore-plans"));
const ExplorePlanSingle = React.lazy(() =>
  import("./pages/explore-plan-single")
);
const DesignBuild = React.lazy(() => import("./pages/whatwedo/design-build"));
const CuratedHomes = React.lazy(() => import("./pages/whatwedo/curated-homes"));
const Architecture = React.lazy(() => import("./pages/whatwedo/architecture"));
const LandAcquisition = React.lazy(() =>
  import("./pages/whatwedo/land-acquisition")
);
const InteriorDesign = React.lazy(() =>
  import("./pages/whatwedo/interior-design")
);
const AveaPoolsAndOutdoorLiving = React.lazy(() =>
  import("./pages/whatwedo/avea-pools-and-outdoor-living")
);
const ArtesianNeighborhoods = React.lazy(() =>
  import("./pages/whatwedo/artesian-neighborhoods")
);
const WarrantyProgram = React.lazy(() => import("./pages/warranty-program"));
const ArtesianHomeCare = React.lazy(() => import("./pages/artesian-home-care"));
const Portfolio = React.lazy(() => import("./pages/portfolio"));
const PortfolioDetails = React.lazy(() => import("./pages/portfolio-details"));
const Search = React.lazy(() => import("./pages/search"));
const Awards = React.lazy(() => import("./pages/awards"));
const Locations = React.lazy(() => import("./pages/locations"));
const Press = React.lazy(() => import("./pages/press"));
const Nahb = React.lazy(() => import("./pages/nahb"));
const Blog = React.lazy(() => import("./pages/blog/blog"));
const BlogDetails = React.lazy(() => import("./pages/blog/blog-details"));
const Archive = React.lazy(() => import("./pages/blog/archive"));
const Notfound = React.lazy(() => import("./pages/notfound"));

const BuildingProcess = React.lazy(() =>
  import("./pages/build-on-your-lot/building-process")
);
const IncludedFeatures = React.lazy(() =>
  import("./pages/build-on-your-lot/included-features")
);
const BuildToRent = React.lazy(() =>
  import("./pages/build-on-your-lot/build-to-rent")
);
const BuildOnYourLot = React.lazy(() =>
  import("./pages/build-on-your-lot/index")
);
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppRouter = () => {
  const [available, setAvailable] = useState(false);

  const [selectedTags, setSelectedTags] = useState([]);

  const handleAvailable = () => {
    setAvailable(true);
  };

  function handleTagClick(event) {
    setAvailable(false);
    const tag = event.target.dataset.tag;

    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  }
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense>
        <Header handleTagClick={handleTagClick} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/showroom" element={<Showroom />} />
          <Route path="/theteam" element={<Theteam />} />
          <Route path="/whatwedo" element={<Whatwedo />} />
          <Route path="/ourprocess" element={<OurProcess />} />
          <Route path="/exploreplans" element={<ExplorePlans />} />

          <Route path="/plan/:slug" element={<ExplorePlanSingle />} />
          <Route path="/whatwedo/design-build" element={<DesignBuild />} />
          <Route path="/whatwedo/curated-homes" element={<CuratedHomes />} />
          <Route path="/whatwedo/architecture" element={<Architecture />} />
          <Route
            path="/whatwedo/land-acquisition"
            element={<LandAcquisition />}
          />
          <Route
            path="/whatwedo/interior-design"
            element={<InteriorDesign />}
          />
          <Route
            path="/whatwedo/avea-pools-and-outdoor-living"
            element={<AveaPoolsAndOutdoorLiving />}
          />
          <Route
            path="/whatwedo/artesian-neighborhoods"
            element={<ArtesianNeighborhoods />}
          />
          <Route path="/warranty-program" element={<WarrantyProgram />} />
          <Route path="/artesian-home-care" element={<ArtesianHomeCare />} />
          <Route
            path="/portfolio"
            element={
              <Portfolio
                handleTagClick={handleTagClick}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                handleAvailable={handleAvailable}
                available={available}
                setAvailable={setAvailable}
              />
            }
          />
          <Route path="/portfolio/:slug" element={<PortfolioDetails />} />
          <Route path="/search" element={<Search />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/press" element={<Press />} />
          <Route path="/press/nahb" element={<Nahb />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />
          <Route path="/blog/archive" element={<Archive />} />
          <Route path="*" element={<Notfound />} />
          <Route path="/build-on-your-lot" element={<BuildOnYourLot />} />
          <Route
            path="/build-on-your-lot/build-to-rent"
            element={<BuildToRent />}
          />
          <Route
            path="/build-on-your-lot/building-process"
            element={<BuildingProcess />}
          />
          <Route
            path="/build-on-your-lot/included-features"
            element={<IncludedFeatures />}
          />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
