// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-type-padding .the-pad {
    padding-top: var(--padding-desktop);
  }
  
  @media screen and (max-width:980px) {
    .block-type-padding .the-pad {
      padding-top: var(--padding-mobile);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/blocks/padding.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;EACrC;;EAEA;IACE;MACE,kCAAkC;IACpC;EACF","sourcesContent":[".block-type-padding .the-pad {\n    padding-top: var(--padding-desktop);\n  }\n  \n  @media screen and (max-width:980px) {\n    .block-type-padding .the-pad {\n      padding-top: var(--padding-mobile);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
