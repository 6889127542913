// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-type-hr .align_right {
  text-align: right;
}

.block-type-hr .align_center {
  text-align: center;
}

.block-type-hr .align_left {
  text-align: left;
}

.block-type-hr hr {
  border: none;
  height: 1px;
  width: 100%;
  background: var(--color-light-gray);
}

.block-type-hr .the-pad {
  padding: var(--padding-desktop);
}

@media screen and (max-width:900px) {
  .block-type-hr .the-pad {
    padding: var(--padding-desktop);
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/blocks/hr.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,WAAW;EACX,mCAAmC;AACrC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE;IACE,+BAA+B;EACjC;AACF","sourcesContent":[".block-type-hr .align_right {\n  text-align: right;\n}\n\n.block-type-hr .align_center {\n  text-align: center;\n}\n\n.block-type-hr .align_left {\n  text-align: left;\n}\n\n.block-type-hr hr {\n  border: none;\n  height: 1px;\n  width: 100%;\n  background: var(--color-light-gray);\n}\n\n.block-type-hr .the-pad {\n  padding: var(--padding-desktop);\n}\n\n@media screen and (max-width:900px) {\n  .block-type-hr .the-pad {\n    padding: var(--padding-desktop);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
