// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input,
textarea,
select {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 8px;

  padding-bottom: 8px;

  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;

  /* Make this element behave like one from the typekit section */
  font-family: "montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0px;
  margin-bottom: 22px;
}

button,
.button {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;

  padding-bottom: 8px;

  border-radius: 1000px;
  border: 1px solid black;
  font-family: "montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0px;
  margin-bottom: 22px;
  -webkit-appearance: none;
  background: none;
}

a.button {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/dynamic/ui.css"],"names":[],"mappings":"AAAA;;;EAGE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;;EAEhB,mBAAmB;;EAEnB,YAAY;EACZ,8BAA8B;EAC9B,kBAAkB;;EAElB,+DAA+D;EAC/D,qCAAqC;EACrC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;;EAEhB,mBAAmB;;EAEnB,qBAAqB;EACrB,uBAAuB;EACvB,qCAAqC;EACrC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["input,\ntextarea,\nselect {\n  padding-left: 0px;\n  padding-right: 0px;\n  padding-top: 8px;\n\n  padding-bottom: 8px;\n\n  border: none;\n  border-bottom: 1px solid black;\n  border-radius: 0px;\n\n  /* Make this element behave like one from the typekit section */\n  font-family: \"montserrat\", sans-serif;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 175%;\n  letter-spacing: 0px;\n  margin-bottom: 22px;\n}\n\nbutton,\n.button {\n  padding-left: 20px;\n  padding-right: 20px;\n  padding-top: 8px;\n\n  padding-bottom: 8px;\n\n  border-radius: 1000px;\n  border: 1px solid black;\n  font-family: \"montserrat\", sans-serif;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 175%;\n  letter-spacing: 0px;\n  margin-bottom: 22px;\n  -webkit-appearance: none;\n  background: none;\n}\n\na.button {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
