// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-type-portfolio-preview .container-anchor{
    display: flex;
    flex-direction: column;
  }
  
  
  .block-type-portfolio-preview .preview-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    white-space: nowrap;
  }
  
  .block-type-portfolio-preview .preview-info .line {
    width: 100%;
    height: 1px;
    margin-top: auto;
    margin-bottom: auto;
    white-space: nowrap;
    background-color: var(--color-light-gray);
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .block-type-portfolio-preview .preview-info p {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 12px;
    color: var(--color-dark-gray);
  }
  
  .block-type-portfolio-preview .preview-info .learn {
    margin-top: auto;
    margin-bottom: auto;
    text-decoration: underline;
    font-style: italic;
    color: black;
    font-size: 11px;
  }
  
  @media screen and (max-width:980px) {
  
    .block-type-portfolio-preview .grid >a {
      grid-column: span 12;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/blocks/portfolio-preview.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;;;EAGA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,yCAAyC;IACzC,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,0BAA0B;IAC1B,kBAAkB;IAClB,YAAY;IACZ,eAAe;EACjB;;EAEA;;IAEE;MACE,oBAAoB;IACtB;EACF","sourcesContent":[".block-type-portfolio-preview .container-anchor{\n    display: flex;\n    flex-direction: column;\n  }\n  \n  \n  .block-type-portfolio-preview .preview-info {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    white-space: nowrap;\n  }\n  \n  .block-type-portfolio-preview .preview-info .line {\n    width: 100%;\n    height: 1px;\n    margin-top: auto;\n    margin-bottom: auto;\n    white-space: nowrap;\n    background-color: var(--color-light-gray);\n    margin-right: 10px;\n    margin-left: 10px;\n  }\n  \n  .block-type-portfolio-preview .preview-info p {\n    margin-top: auto;\n    margin-bottom: auto;\n    font-size: 12px;\n    color: var(--color-dark-gray);\n  }\n  \n  .block-type-portfolio-preview .preview-info .learn {\n    margin-top: auto;\n    margin-bottom: auto;\n    text-decoration: underline;\n    font-style: italic;\n    color: black;\n    font-size: 11px;\n  }\n  \n  @media screen and (max-width:980px) {\n  \n    .block-type-portfolio-preview .grid >a {\n      grid-column: span 12;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
