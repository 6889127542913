import AOS from "aos";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import AppRouter from "./AppRouter";
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <HelmetProvider>
      <AppRouter />
    </HelmetProvider>
  );
}

export default App;
